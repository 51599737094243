/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
#home {
  width: 412px;
  margin: 16px 0 5px 39px;
}
.desk {
  width: 96%;
  max-width: 1024px;
}
.banner > .desk {
  position: relative;
}
.navigation {
  margin-top: 15px;
}
.contact {
  margin-left: 1.46484375%;
}
.aside__claim {
  float: left;
  width: auto;
  margin: 51px 0 0 131px;
  text-align: left;
}
.partner {
  float: right;
  width: auto;
  margin-right: 2.9296875%;
  margin-bottom: 13px;
}
.partner__logo {
  float: left;
}
.partner__logo--2 {
  float: right;
}
.partner__logo--3 {
  clear: both;
  margin-top: 10px;
}
#home:before {
  bottom: -20px;
  left: -60px;
}
#home:after {
  right: 50px;
}
.ew {
  z-index: 1;
  position: absolute;
  background: url(/images/edelweiss.svg) no-repeat;
  background-size: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  -webkit-appearance: none;
}
.ew:hover + .ew {
  -o-animation: swirl 1s ease-in-out;
  -moz-animation: swirl 1s ease-in-out;
  -webkit-animation: swirl 1s ease-in-out;
  animation: swirl 1s ease-in-out;
}
@-webkit-keyframes swirl {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-o-keyframes swirl {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
  }
}
@-moz-keyframes swirl {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@keyframes swirl {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ew--1 {
  bottom: -17px;
  left: 484px;
  width: 42px;
  height: 46px;
}
.ew--2 {
  top: 14px;
  left: 610px;
  width: 49px;
  height: 53px;
}
.ew--3 {
  top: 40px;
  left: 836px;
  width: 49px;
  height: 53px;
}
.ew--4 {
  top: 46px;
  left: 900px;
  width: 33px;
  height: 36px;
}
.ew--5 {
  bottom: 10px;
  left: 520px;
  width: 60px;
  height: 65px;
}
.ew--6 {
  bottom: -26px;
  left: 896px;
  width: 60px;
  height: 65px;
}
.navi {
  width: 100%;
  text-align: center;
}
.navi > .item {
  float: none;
  display: inline-block;
}
.menu {
  font-weight: 700;
  padding: 0.4em 0.77em;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0.72184793%;
  margin-left: 0.72184793%;
  width: 98.55630414%;
}
.area h2,
.area .foot {
  margin-right: 1.46484375%;
  margin-left: 1.46484375%;
}
.area .part,
.area > .grid table {
  margin-right: 1.46484375%;
  margin-left: 1.46484375%;
  width: 97.0703125%;
}
.area .tiny {
  width: 47.0703125%;
}
.area > .slim {
  width: 23.55630414%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 6.12870276%;
  margin-left: 6.12870276%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 87.74259448%;
}
.area > .slim .tiny {
  width: 87.74259448%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.wide.wide {
  width: 73.55630414%;
}
.wide h2,
.wide .part,
.wide.grid table,
.wide .foot {
  margin-right: 1.96270854%;
  margin-left: 1.96270854%;
}
.wide .tall,
.wide.grid table {
  width: 96.07458292%;
}
.wide .tiny {
  width: 46.07%;
}
.cb-layout1 .wide {
  width: 48.55630414%;
}
.cb-layout1 .wide h2,
.cb-layout1 .wide .part,
.cb-layout1 .wide.grid table,
.cb-layout1 .wide .foot {
  margin-right: 2.97324083%;
  margin-left: 2.97324083%;
}
.cb-layout1 .wide .part,
.cb-layout1 .wide.grid table {
  width: 94.05351833%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.area {
  margin-left: -0.73242187%;
  width: 101.46484375%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-large.css.map */